import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { invoiceTypes } from "../../../../state/entities/invoices";
import {
  getDisputedInvoicesById,
  getOpenInvoices,
  getRequestedInvoices
} from "../../../../state/entities/invoices/selectors";
import {
  setSelectedInvoiceIds,
  undoDispute
} from "../../../../state/entities/invoices/slice";
import { StoreType } from "../../../../state/types";
import { sortInvoices, getColumns } from "./utils";
import { getSelectedVendor } from "../../../../state/entities/vendors/selectors";
import { getGlobalFeatureFlags } from "../../../../state/entities/shared/selectors";
import { featureEnabled } from "../../../../state/entities/shared/utils";
import { useMobile } from "../../../../hooks/useMobile";
import { useModal, modalTypes } from "../../../../globalModal";

export const useInvoicesToDisplay = (): invoiceTypes.Invoice[] => {
  return useSelector((state: StoreType) => {
    const { requestInvoiceIds } = state.requests;

    if (state.invoices.showAllInvoices) {
      return sortInvoices(getOpenInvoices(state), requestInvoiceIds);
    } else {
      return sortInvoices(getRequestedInvoices(state), requestInvoiceIds);
    }
  });
};

export const useDisputedInvoicesToDisplay =
  (): invoiceTypes.DisputedInvoiceById => {
    return useSelector((state: StoreType) => getDisputedInvoicesById(state));
  };

export const useSelectedMap = (invoices: invoiceTypes.Invoice[]) => {
  const dispatch = useDispatch();

  const selectedMap = useSelector((state: StoreType) => {
    const { selectedInvoiceIds } = state.invoices;

    return invoices.reduce<Record<string, boolean>>((acc, inv) => {
      acc[inv.id] = selectedInvoiceIds.includes(inv.id);
      return acc;
    }, {});
  });

  const setSelected = (selected: Record<string, boolean>) => {
    const newSelectedInvoiceIds = Object.keys(selected).filter(
      // eslint-disable-next-line security/detect-object-injection
      id => selected[id]
    );

    dispatch(
      setSelectedInvoiceIds({ selectedInvoiceIds: newSelectedInvoiceIds })
    );
  };

  return { selectedMap, setSelected };
};

export const useUndoDispute = () => {
  const dispatch = useDispatch();
  return (invoice: invoiceTypes.DisputedInvoice) => {
    const undoDisputeEntity: invoiceTypes.UndoDispute = {
      disputedInvoice: invoice
    };

    dispatch(undoDispute(undoDisputeEntity));
  };
};

export const useGetSelectedVendor = () => {
  return useSelector(getSelectedVendor);
};

export const useColumnFlags = () => {
  const vendor = useGetSelectedVendor();
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);

  const isShipToEnabled = !!(
    vendor.invoiceFilterColumns?.length &&
    vendor.invoiceFilterColumns?.length > 0
  );

  const isInvoicePoRefIdEnabled = featureEnabled(
    vendor,
    globalFeatureFlags,
    "displayInvoicePoRefId"
  );

  const isInvoiceDateEnabled = featureEnabled(
    vendor,
    globalFeatureFlags,
    "displayExternalInvoiceDate"
  );
  const { mobile } = useMobile();

  return {
    isShipToEnabled,
    isInvoicePoRefIdEnabled,
    isInvoiceDateEnabled,
    vendor,
    mobile
  };
};

export const useGetColumns = () => {
  const columnFlags = useColumnFlags();

  return getColumns(
    columnFlags.mobile,
    columnFlags.isInvoicePoRefIdEnabled,
    columnFlags.isInvoiceDateEnabled,
    columnFlags.isShipToEnabled,
    columnFlags.vendor
  );
};

const undoModal = (
  invoice: invoiceTypes.DisputedInvoice,
  undoDisputedInvoice: (invoice: invoiceTypes.DisputedInvoice) => void,
  hideModal: () => void
): modalTypes.ModalType => {
  return {
    type: modalTypes.UNDO_DISPUTED_INVOICE_MODAL,
    props: {
      onClose: () => {
        hideModal();
      },
      onSubmit: () => {
        undoDisputedInvoice(invoice);
        hideModal();
      },
      invoice
    }
  };
};

const editModal = (
  invoice: invoiceTypes.Invoice,
  hideModal: () => void
): modalTypes.ModalType => {
  return {
    type: modalTypes.EDIT_INVOICE,
    props: {
      onClose: () => {
        hideModal();
      },
      invoice
    }
  };
};

export const useDisputeModal = (
  invoice: invoiceTypes.Invoice | invoiceTypes.DisputedInvoice
) => {
  const undoDisputedInvoice = useUndoDispute();
  const { showModal, hideModal } = useModal();

  const modalType: modalTypes.ModalType = invoiceTypes.isDisputedInvoice(
    invoice
  )
    ? undoModal(invoice, undoDisputedInvoice, hideModal)
    : editModal(invoice, hideModal);

  return () => showModal(modalType);
};

export const useDropDownMaxHeight = (
  selectedOption: Record<string, string>
) => {
  const [dropDownMaxHeight, setDropDownMaxHeight] = React.useState("125px");
  const tableRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const handleTransitionEnd = () => {
      if (tableRef.current) {
        setDropDownMaxHeight(`${tableRef.current.offsetHeight - 100}px`);
      }
    };

    const node = tableRef.current;
    if (node) {
      node.addEventListener("transitionend", handleTransitionEnd);
    }

    return () => {
      if (node) {
        node.removeEventListener("transitionend", handleTransitionEnd);
      }
    };
  }, [selectedOption]);
  return { dropDownMaxHeight, tableRef };
};
