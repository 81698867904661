// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {
  formatCurrency,
  formatShortDate,
  sortCurrency,
  sortDate,
  sortString
} from "@billfire/toybox";
import { isCredit, isPastDue } from "../../../../state/entities/invoices/utils";

import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import { CustomTableColumn } from "./styled";
import PencilIcon from "./PencilIcon";
import PrefixEllipsis from "../../../PrefixEllipsis";
import { Vendor } from "../../../../state/entities/vendors/types";
import { invoiceTypes } from "../../../../state/entities/invoices";
import InvoiceLink from "../../../InvoiceLink";
import MobileCell from "./MobileCell";

export interface InvoiceRow extends invoiceTypes.Invoice {
  rowId: string;
  displayAmount: number;
  checkboxProps: { id: string };
}

const columnWidths = {
  dueDate: "10%",
  invoice: "14%",
  invoiceDate: (isInvoicePoRefIdEnabled: boolean) =>
    isInvoicePoRefIdEnabled ? "10%" : "19%",
  poRef: (isInvoiceDateEnabled: boolean) =>
    isInvoiceDateEnabled ? "12%" : "21%",
  shipTo: (isInvoiceDateEnabled: boolean) =>
    isInvoiceDateEnabled ? "30%" : "26%",
  displayAmount: "13%",
  id: "5%"
};

const poRefColumn = (isInvoiceDateEnabled: boolean) => ({
  name: "poRefId",
  label: "PO/REF Number",
  width: columnWidths.poRef(isInvoiceDateEnabled),
  render: (value: string) => {
    return value === null ? (
      "-"
    ) : (
      <span>
        <PrefixEllipsis text={value} />
      </span>
    );
  },
  sortFn: sortString
});

const invoiceDateColumn = (isInvoicePoRefIdEnabled: boolean) => ({
  name: "externalInvoiceDate",
  label: "Invoice Date",
  width: columnWidths.invoiceDate(isInvoicePoRefIdEnabled),
  render: (value: string) => {
    return <CustomTableColumn isPastDue={false}>{value}</CustomTableColumn>;
  },
  formatFn: formatShortDate,
  sortFn: sortString
});

const getShipToColumn = (isInvoiceDateEnabled: boolean) => ({
  name: "shipTo",
  label: "Ship-To",
  width: columnWidths.shipTo(isInvoiceDateEnabled),
  sortFn: sortString
});

const getMobileColumns = () => [
  {
    name: "id",
    label: "Select All",
    width: "100%",
    render: (value: string, item: InvoiceRow) => {
      return <MobileCell invoice={item} />;
    }
  }
];

const getDueDateColumn = (vendor: Vendor) => ({
  name: "dueDate",
  label: "Due Date",
  width: columnWidths.dueDate,
  render: (value: string, item: InvoiceRow) => (
    <CustomTableColumn isPastDue={isPastDue(item, vendor)}>
      {value}
    </CustomTableColumn>
  ),
  formatFn: formatShortDate,
  sortFn: sortDate
});

const getInvoiceColumn = () => ({
  name: "displayInvNum",
  label: "Invoice",
  width: columnWidths.invoice,
  render: (value: string, item: invoiceTypes.DisputedInvoice) => (
    <InvoiceLink invoiceId={item.id} />
  ),
  sortFn: sortString
});

const getAmountColumn = () => ({
  name: "displayAmount",
  label: "Amount",
  width: columnWidths.displayAmount,
  formatFn: formatCurrency,
  sortFn: sortCurrency
});

const getEditColumn = () => ({
  name: "id",
  label: "Edit",
  width: columnWidths.id,
  render: (_: string, item: invoiceTypes.Invoice) =>
    item.dueDate ? <PencilIcon invoice={item} /> : ""
});

const getDesktopColumns = (
  isInvoicePoRefIdEnabled: boolean,
  isInvoiceDateEnabled: boolean,
  isShipToEnabled: boolean,
  vendor: Vendor
) => [
  getDueDateColumn(vendor),
  ...(isInvoiceDateEnabled ? [invoiceDateColumn(isInvoicePoRefIdEnabled)] : []),
  getInvoiceColumn(),
  ...(isShipToEnabled ? [getShipToColumn(isInvoiceDateEnabled)] : []),
  ...(isInvoicePoRefIdEnabled ? [poRefColumn(isInvoiceDateEnabled)] : []),
  getAmountColumn(),
  getEditColumn()
];

export const getColumns = (
  mobile: boolean,
  isInvoicePoRefIdEnabled: boolean,
  isInvoiceDateEnabled: boolean,
  isShipToEnabled: boolean,
  vendor: Vendor
): Column[] =>
  mobile
    ? [...getMobileColumns()]
    : [
        ...getDesktopColumns(
          isInvoicePoRefIdEnabled,
          isInvoiceDateEnabled,
          isShipToEnabled,
          vendor
        )
      ];

export const getRows = (
  invoices: invoiceTypes.Invoice[],
  disputedInvoices: invoiceTypes.DisputedInvoiceById
): InvoiceRow[] => {
  return invoices.map(inv => {
    return disputedInvoices.byId[inv.id]
      ? {
          ...disputedInvoices.byId[inv.id],
          displayAmount: disputedInvoices.byId[inv.id].newAmount,
          rowId: inv.id,
          checkboxProps: {
            id: inv.id
          }
        }
      : {
          ...inv,
          rowId: inv.id,
          displayAmount: inv.openAmount,
          checkboxProps: {
            id: inv.id
          }
        };
  });
};

export const dateSortAscending = (a: string, b: string) => {
  const aTimestamp = new Date(a).getTime();
  const bTimestamp = new Date(b).getTime();

  return aTimestamp - bTimestamp < 0 ? -1 : 1;
};

export const compareFunction = (
  a: invoiceTypes.Invoice,
  b: invoiceTypes.Invoice
) => {
  if (isCredit(a)) {
    return -1;
  }

  if (isCredit(b)) {
    return 1;
  }

  return dateSortAscending(a.dueDate, b.dueDate);
};

export const sortInvoices = (
  invoices: invoiceTypes.Invoice[],
  requestInvoiceIds: string[]
): invoiceTypes.Invoice[] => {
  return invoices.sort((a, b) => {
    const aIsRequested = requestInvoiceIds.includes(a.id);
    const bIsRequested = requestInvoiceIds.includes(b.id);

    if (aIsRequested !== bIsRequested) {
      return aIsRequested ? -1 : 1;
    }

    return compareFunction(a, b);
  });
};
